var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("amendments")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "d-flex flex-row-reverse"
  }, [[null, 5].includes(_vm.amendment_index_status) ? _c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    attrs: {
      "disabled": _vm.btnMohon
    },
    on: {
      "click": _vm.applyAmendment
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.apply-new")))]) : _vm._e(), _vm.btnPrint && ![null, 5].includes(_vm.amendment_index_status) ? _c('b-button', {
    staticClass: "btn-sm btn-success m-1",
    on: {
      "click": _vm.printWasiat
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("download-last-will")) + " ")]) : _vm._e(), _vm.btnPrint && [null, 5].includes(_vm.amendment_index_status) ? _c('b-button', {
    staticClass: "btn-sm btn-success m-1",
    on: {
      "click": _vm.printWasiat
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("download-comp-will")) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "row mt-3"
  }, [_c('table', {
    staticClass: "table table-hover"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.date-of-amendment")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.of")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Status")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', [_vm._l(this.amendment_array, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(_vm._f("getConvertDate")(item.created_at)))]), _c('td', [_vm._v(_vm._s(item.count))]), _c('td', [_vm._v(" " + _vm._s(_vm._f("pindaanStatus")(item.status)) + " ")]), _c('td', [item.status == 0 ? _c('b-button', {
      staticClass: "btn-sm btn-danger",
      attrs: {
        "block": ""
      },
      on: {
        "click": _vm.openPindaan
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")))]) : _vm._e(), item.status == 1 ? _c('p', [_vm._v(_vm._s(_vm.$t("awaiting-confirmation")))]) : _vm._e(), item.status == 2 ? _c('b-button', {
      staticClass: "btn-sm btn-success",
      on: {
        "click": function ($event) {
          return _vm.VerifiedWasiat(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("verification-draft-will")) + " ")]) : _vm._e(), item.status == 4 ? _c('p', [_vm._v(_vm._s(_vm.$t("draft-received")))]) : _vm._e(), item.status == 5 ? _c('p', [_vm._v(_vm._s(_vm.$t("print-cert")))]) : _vm._e()], 1)]);
  }), _vm.amendment_array.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_c('h5', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("amendment.no-records")))])])]) : _vm._e()], 2)])]), _c('b-modal', {
    ref: "payAmendment",
    attrs: {
      "title": "Jumlah Pembayaran",
      "no-enforce-focus": true,
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('label', {
    staticClass: "label-agree-term"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("agree-with-payment")))])])]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('label', [_c('strong', [_vm._v("RM" + _vm._s(_vm.amendment_price))])])])]), _c('b-button', {
    staticClass: "btn btn-danger",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.paymentGateWay
    }
  }, [_vm._v(_vm._s(_vm.$t("agree")))])], 1)], 1)]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }